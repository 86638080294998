import React from "react";
import { Partner } from "./PartnerLogo.d";

import "./PartnerLogo.scss";

const PartnerLogo = ({ partners }: { partners: Partner[] }) => {
  return (
    <div className="d-flex justify-content-between my-3 scrollable border-bottom-dark mb-3 mb-md-5">
      {partners.map((partner, index: number) => (
        <div
          key={index}
          className="position-relative px-2 px-xl-4 align-content-center partner-logo"
        >
          <img
            src={`/assets/images/partner/${partner.logo}`}
            alt={partner.name}
            className="object-fit-contain"
          />
          {index === 0 && (
            <div className="short-bar-left position-absolute"></div>
          )}
          <div className="short-bar-right position-absolute"></div>
        </div>
      ))}
    </div>
  );
};

export default PartnerLogo;
