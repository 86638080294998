import React, { useState } from "react";
import DstNavbar from "../../../components/DstNavbar/DstNavbar";
import PreFooterOmnes from "../../../components/PreFooterOmnes/PreFooterOmnes";
import DstFooter from "../../../components/DstFooter/DstFooter";
import { Container, Row } from "react-bootstrap";
import HeaderContent from "../../../components/HeaderContent/HeaderContent";
import DstButton from "../../../components/DstButton/DstButton";
import Title from "../../../components/DstTitle/DstTitle";
import InfoCard from "../../../components/InfoCard/InfoCard";
import { ContactSection } from "../Formation.component";
import Popup from "../../../components/Popup/Popup";
import DstSlider from "../../../components/DstSlider/DstSlider";
import HubspotForm from "../../../components/HubspotForm/HubspotForm";
import { useNavigate } from "react-router-dom";

const slides = [
  {
    imageUrl: "/assets/images/secteurs/navale/construction-navale.png",
    text: "Dessinateur construction navale",
    link: "/formation-dessinateur-construction-navale",
  },
  {
    imageUrl: "/assets/images/secteurs/navale/projeteur-tuyauterie.png",
    text: "Dessinateur projeteur tuyauterie",
    link: "/formation-dessinateur-projeteur-tuyauterie",
  },
];

const FormationsNavale = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleShow = () => {
    setShowModal(true);
  };
  return (
    <React.Fragment>
      <DstNavbar />
      <HeaderContent
        alt="automobile"
        image="/assets/images/home/navale.png"
        title={
          <h1>
            Formez vos futurs collaborateurs au secteur du{" "}
            <span className="blue-font">Navale</span>
          </h1>
        }
        content={
          <div className="mt-3">
            <p>
              Les activités du secteur naval s'accompagnent de progrès
              technologiques qui évoluent sans cesse. Les nouveaux outils
              amènent de nouvelles compétences qui{" "}
              <span className="blue-font">doivent être maitrisées.</span>
            </p>
            <p>
              Trouvez vos futurs collaborateurs dans ce domaine grâce à
              DataScientest !
            </p>
          </div>
        }
        dstButton={{
          value: "J’envoie mon cv",
          clickFunction: handleShow,
          variant: "secondary",
          disableAnimation: true,
        }}
      />
      <Container>
        <div>
          <Title
            text="Formations"
            tag="h2"
            className="h3"
            color="#2424ff"
            underlineColor="#9D9FAB"
            underlineWidth={60}
          />
          <h1>
            Découvrez nos{" "}
            <span className="blue-font">formations en Navale</span>
          </h1>
          <div>
            <DstSlider slides={slides} height="150px" />
          </div>
          <div className="d-flex flex-column align-items-center">
            <DstButton
              value="PRENDRE RDV"
              btnClass="l-black mb-5"
              disableAnimation={true}
              variant="secondary"
              clickFunction={() => navigate("/rdv")}
            />
          </div>
        </div>
      </Container>
      <div className="dark-background white-font py-3 py-md-5">
        <Container className="my-3 my-md-5">
          <Title
            text="Recrutement"
            tag="div"
            className="h3"
            color="#20FFA4"
            underlineColor="#9D9FAB"
            textUppercase
          />
          <h3 className="h2 mb-3 mb-md-5">
            Comment s’organise le{" "}
            <span className="green-font">recrutement</span>
          </h3>
          <Row>
            <InfoCard
              title="Définition du besoin"
              text="DataScientest identifie les besoins spécifiques des entreprises et détermine les profils à sourcer, ainsi que la formation adaptée à ces profils."
              imageSrc="/assets/images/icon/write.svg"
              borderColor={"#F7F7F7"}
            />
            <InfoCard
              title="Sourcing"
              text="En accord avec les critères de sélection, DataScientest identifie
            et sélectionne les profils les plus proches des besoins
            spécifiques de l’entreprise. Ces profils bénéficieront d’une
            formation ciblée pour combler leurs lacunes."
              imageSrc="/assets/images/icon/light.svg"
              borderColor={"#F7F7F7"}
            />
            <InfoCard
              title="Formation"
              text="Une fois les profils sélectionnés et validés par l’entreprise, ils
            entament une formation intensive d’une durée maximale de 11
            semaines. À l’issue de cette formation, ils seront pleinement
            qualifiés et certifiés, prêts à répondre aux besoins de
            l’entreprise."
              imageSrc="/assets/images/icon/book.svg"
              borderColor={"#F7F7F7"}
            />
            <InfoCard
              title="Validation et embauche"
              text="La validation de la formation, et donc de l’embauche, est soumise
            à une soutenance à laquelle les équipes de l’entreprise sont
            invitées à assister. La décision finale d’embauche repose
            entièrement entre les mains de l’entreprise cliente."
              imageSrc="/assets/images/icon/work.svg"
              borderColor={"#F7F7F7"}
            />
          </Row>
          <div className="text-center">
            <DstButton
              value="télécharger la brochure"
              btnClass="m-bold"
              clickFunction={handleShow}
            />
          </div>
        </Container>
      </div>
      <Container className="mt-3 mt-md-5">
        <ContactSection
          portalId="19831339"
          formId="74d3c0da-d384-48cf-aa2f-094ee3e83754"
        />
      </Container>
      <PreFooterOmnes />
      <DstFooter />
      <Popup
        show={showModal}
        onClose={handleClose}
        modalTitle="Syllabus Secteur Navale"
      >
        <div>
          <HubspotForm
            portalId="19831339"
            formId="74d3c0da-d384-48cf-aa2f-094ee3e83754"
          />
        </div>
      </Popup>
    </React.Fragment>
  );
};

export default FormationsNavale;
