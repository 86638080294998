import React from "react";
import DstButton from "../../components/DstButton/DstButton";
import DstNavbar from "../../components/DstNavbar/DstNavbar";
import DstFooter from "../../components/DstFooter/DstFooter";
import HeaderContent from "../../components/HeaderContent/HeaderContent";
import InfoCard from "../../components/InfoCard/InfoCard";
import PreFooterOmnes from "../../components/PreFooterOmnes/PreFooterOmnes";
import Title from "../../components/DstTitle/DstTitle";

import { Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Ecole = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <DstNavbar />
      <HeaderContent
        title={
          <h1>Forgez les compétences de vos équipes à l’industrie de demain</h1>
        }
        content={
          <div className="mt-3">
            <p>
              La raison d’être du groupe DST est d’aider ses partenaires à faire
              face à la pénurie de compétences spécialisées dans certains pans
              de l’économie.
            </p>
            <p>
              Sous l’impulsion de nos partenaires pour les métiers de la Tech,
              le groupe DST a ainsi lancé sa filiale DST Ingénierie en début
              d’année 2023.
            </p>
            <p>
              Nous comptons déjà pour clients des groupes comme Alten, ACC,
              Michelin, Stellantis ou encore Segula…
            </p>
          </div>
        }
        dstButton={{
          value: "Prendre RDV",
          btnClass: "mt-lg-5",
          variant: "secondary",
          clickFunction: () => navigate("/rdv"),
        }}
      />
      <div className="dark-background white-font py-3 py-md-5">
        <Container className="my-3 my-md-5">
          <Title
            text="Le Smart Recruiting"
            tag="h3"
            color="#20FFA4"
            underlineColor="#9D9FAB"
            textUppercase
          />
          <h3 className="h2 mb-3 mb-md-5">
            4 étapes pour un{" "}
            <span className="green-font">collaborateur sur-mesure</span>
          </h3>

          <Row>
            <InfoCard
              title="Définition du besoin"
              text="Le profil parfait tant en termes de hard skills (stack, certifications,
                outils…) que de soft skills (posture, gestion d’équipe…). En accord avec
                cette fiche de poste, nous définirons les bons profils à sourcer et la
                bonne formation à leur dispenser."
              imageSrc="/assets/images/icon/write.svg"
              borderColor={"#F7F7F7"}
            />
            <InfoCard
              title="Sourcing"
              text="En accord avec les critères de sélection, DataScientest identifie
            et sélectionne les profils les plus proches des besoins
            spécifiques de l’entreprise. Ces profils bénéficieront d’une
            formation ciblée pour combler leurs lacunes."
              imageSrc="/assets/images/icon/light.svg"
              borderColor={"#F7F7F7"}
            />
            <InfoCard
              title="Formation"
              text="Une fois les profils sélectionnés et validés par l’entreprise, ils
            entament une formation intensive d’une durée maximale de 11
            semaines. À l’issue de cette formation, ils seront pleinement
            qualifiés et certifiés, prêts à répondre aux besoins de
            l’entreprise."
              imageSrc="/assets/images/icon/book.svg"
              borderColor={"#F7F7F7"}
            />
            <InfoCard
              title="Validation et embauche"
              text="La validation de la formation, et donc de l’embauche, est soumise
            à une soutenance à laquelle les équipes de l’entreprise sont
            invitées à assister. La décision finale d’embauche repose
            entièrement entre les mains de l’entreprise cliente."
              imageSrc="/assets/images/icon/work.svg"
              borderColor={"#F7F7F7"}
            />
          </Row>
          <div className="text-center">
            <DstButton
              value="Prendre rdv"
              btnClass="m-bold"
              clickFunction={() => navigate("/rdv")}
            />
          </div>
        </Container>
      </div>
      <Container className="mt-3 mt-md-5">
        <Title
          text={"DST Ingénierie"}
          tag="h2"
          className="h3"
          color="#2424FF"
          textUppercase
        />
        <h3 className="h2 mb-md-5">
          Sur-mesure, par <span className="blue-font">essence</span>
        </h3>
      </Container>
      <HeaderContent
        reversed
        content={
          <div className="mt-3">
            <p>
              La marque de fabrique de DST Ingénierie repose sur notre totale
              adaptation à vos enjeux et votre environnement.
            </p>
            <p>
              Loin des organismes traditionnels, DST ingénierie ne dispose pas
              d’un “catalogue” figé mais vous accompagne sur l’intégralité de
              vos projets de recrutement, en nous adaptant totalement à votre
              contexte et vos enjeux.
            </p>
          </div>
        }
        contentCenter
        image="/assets/images/header-img/industry-engineer.png"
        alt="Industry Engineer"
        dstButton={{
          value: "Prendre RDV",
          variant: "secondary",
          clickFunction: () => navigate("/rdv"),
        }}
        buttonCenter
      />
      <PreFooterOmnes />
      <DstFooter />
    </React.Fragment>
  );
};

export default Ecole;
