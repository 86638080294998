import React from "react";
import { Col, Row } from "react-bootstrap";

import HubspotForm from "../../components/HubspotForm/HubspotForm";
import Title from "../../components/DstTitle/DstTitle";

export const ContactSection = ({
  color = "#2424FF",
  formId,
  imageSrc = "/assets/images/contact.png",
  imageAlt = "Contact",
  portalId,
  subTitle = (
    <React.Fragment>
      Vous êtes <span className="blue-font">intéressé·e</span> ?
    </React.Fragment>
  ),
  title = "CONTACTEZ-NOUS",
}: {
  color?: string;
  formId: string;
  imageSrc?: string;
  imageAlt?: string;
  portalId: string;
  subTitle?: string | JSX.Element;
  title?: string;
}) => {
  return (
    <Row className="mt-3 mt-md-5">
      <Col md>
        {title && (
          <Title
            className="h3"
            tag="h2"
            color={color}
            text={title}
            underlineColor="#9D9FAB"
            textUppercase
          />
        )}
        {subTitle && <div className="s-regular h2">{subTitle}</div>}
        {portalId && formId && (
          <HubspotForm portalId={portalId} formId={formId} />
        )}
      </Col>
      <Col md className="d-flex align-self-center px-0">
        {imageSrc && (
          <img src={imageSrc} alt={imageAlt} className="img-fluid" />
        )}
      </Col>
    </Row>
  );
};
