import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import DstButton from "../../components/DstButton/DstButton";
import DstFooter from "../../components/DstFooter/DstFooter";
import DstNavbar from "../../components/DstNavbar/DstNavbar";
import FormationSection from "../../components/FormationsSection/FormationsSection";
import ImgDecoration from "../../components/ImgDecoration/ImgDecoration";
import PreFooterOmnes from "../../components/PreFooterOmnes/PreFooterOmnes";
import Title from "../../components/DstTitle/DstTitle";
import YouTubeVideo from "../../components/YouTubeVideo/YouTubeVideo";

import { useNavigate } from "react-router-dom";

import "./Home.scss";

const Home = () => {
  const navigate = useNavigate();

  const UnderlineSize = ({
    Width = "100%",
    bgColor = "#f7f7f7",
  }: {
    Width?: string;
    bgColor?: string;
  }) => {
    return (
      <div className="underline" style={{ width: Width }}>
        <div className="long-bar" style={{ backgroundColor: bgColor }} />
      </div>
    );
  };
  return (
    <React.Fragment>
      <DstNavbar />
      <div className="text-center white-font position-relative d-flex justify-content-center">
        <img
          src="/assets/images/home/ingenieur.png"
          alt="ingenieur"
          className="img-fluid d-none d-sm-block"
        />
        <Col md={9} lg={6} xl={3} className="home-title px-0 py-3">
          <h1>DST Ingénierie</h1>
          <div className="h3 col-12">
            Forge les compétences de vos équipes dans les différents secteurs de
            l’industrie
          </div>
          <DstButton
            value="PRENDRE RDV"
            btnClass="l-black"
            clickFunction={() => navigate("/rdv")}
          />
        </Col>
      </div>
      <Container className="my-3 my-md-5">
        <Title
          className="h3"
          text="Nos formations"
          color="#2424FF"
          tag="h2"
          underlineWidth={15}
          underlineColor="#9D9FAB"
          textUppercase
        />
        <p className="h3">
          Découvrez nos formations en{" "}
          <span className="blue-font">Ingénierie</span>
        </p>
        <FormationSection textColor={"#2424FF"} />
      </Container>
      <div className="dark-background white-font">
        <Container className="py-3 py-md-5">
          <Title
            className="h3"
            text="Notre pédagogie"
            color="#20FFA4"
            tag="h2"
            underlineWidth={15}
            underlineColor="#9D9FAB"
            textUppercase
          />
          <p className="h3 mb-3 mb-mb-5">
            Choisissez une{" "}
            <span className="green-font">formation d’experts</span>
          </p>
          <Row className="mt-md-4">
            <Col md>
              <div className="d-flex align-items-center mb-3 position-relative">
                <ImgDecoration
                  title="Image avec barres supérieure et inférieure"
                  imageUrl="/assets/images/icon/rule.svg"
                  height={60}
                  showBarBottom={false}
                  bgColor="#F7F7F7"
                />
                <UnderlineSize Width="60%" />{" "}
                <div className="s-semibold">Une pédagogie innovante</div>
              </div>
              <div className="d-flex align-items-center mb-3 position-relative">
                <ImgDecoration
                  title="Image avec barres supérieure et inférieure"
                  imageUrl="/assets/images/icon/work.svg"
                  height={60}
                  showBarBottom={false}
                  bgColor="#F7F7F7"
                />
                <UnderlineSize Width="80%" />{" "}
                <div className="s-semibold">Des professeurs experts</div>
              </div>
              <div className="d-flex align-items-center mb-3 position-relative">
                <ImgDecoration
                  title="Image avec barres supérieure et inférieure"
                  imageUrl="/assets/images/icon/fiche.svg"
                  height={60}
                  showBarBottom={false}
                  bgColor="#F7F7F7"
                />
                <UnderlineSize Width="65%" />{" "}
                <div className="s-semibold">Accompagnement premium</div>
              </div>
            </Col>
            <Col md className="align-self-center">
              <p>
                Toutes nos formations sont dispensées majoritairement à
                distance. Ce modèle permet d’offrir une grande flexibilité à nos
                apprenants.
              </p>
              <p>
                Nous construisons les programmes pédagogiques avec les meilleurs
                experts et spécialistes des différents secteurs pour s’assurer
                de la qualité des modules octroyés.
              </p>
              <p>
                Les apprenants sont accompagnés tout au long de la formation par
                les équipes de DST Ingénierie pour que l’expérience
                d’apprentissage soit la plus réussie possible.
              </p>
            </Col>
          </Row>
          <div className="text-center mt-2">
            <DstButton
              value="PRENDRE RDV"
              btnClass="l-black"
              clickFunction={() => navigate("/rdv")}
            />
          </div>
        </Container>
      </div>
      <Container className="py-3 py-md-5">
        <Title
          className="h3"
          text="Nos vidéos"
          color="#2424FF"
          tag="h2"
          underlineWidth={15}
          underlineColor="#9D9FAB"
          textUppercase
        />
        <p className="h3 mb-3 mb-mb-5">
          En savoir plus sur l’<span className="blue-font">Ingénierie</span>
        </p>
        <YouTubeVideo
          videoId="-xqaAMLecXk"
          widthPercentage={50}
          mobileWidthPercentage={90}
          decoration
        />
      </Container>
      <PreFooterOmnes />
      <DstFooter />
    </React.Fragment>
  );
};

export default Home;
