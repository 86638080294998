import { Col, Row } from "react-bootstrap";
import { formationData } from "./FormationsSection.mock";

import "./FormationsSection.scss";
import ListItem from "../DstIcon/ListItem";

const FormationSection = ({
  imageSrc,
  imageTitle,
  roles,
  textColor,
}: {
  imageSrc: string;
  imageTitle: string;
  roles: string[];
  textColor: string;
}) => (
  <Col xs={6} md={4} lg className="px-lg-0 border-end mt-3">
    <div className="position-relative">
      <img src={imageSrc} alt={imageTitle} className="img-fluid" />
      <div className="white-font position-absolute top-50 start-50 translate-middle l-black text-uppercase">
        {imageTitle}
      </div>
    </div>
    <ul
      className="list-check xs-semibold mt-3 ps-2"
      style={{ color: textColor }}
    >
      {roles.map((role, index) => (
        <li key={index}>
          <ListItem color={textColor} className="me-2" />
          {role}
        </li>
      ))}
    </ul>
  </Col>
);

const FormationsSection = ({ textColor }: { textColor: string }) => (
  <Row className="mt-md-3">
    {formationData.map((data, index) => (
      <FormationSection key={index} {...data} textColor={textColor} />
    ))}
  </Row>
);

export default FormationsSection;
