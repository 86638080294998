import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import DstButton from "../DstButton/DstButton";

const PreFooterOmnes = () => {
  const stats = [
    { number: "15", text: "écoles" },
    { number: "200 000", text: "alumni" },
    { number: "3 000", text: "experts" },
    { number: "40 000", text: "étudiants" },
    { number: "20", text: "campus en France et à l’étranger" },
  ];
  const category = [
    "Management",
    "Ingénieurs",
    "Communication",
    "Sciences politiques et Relations internationales",
    "Création et design",
  ];
  return (
    <div className="dark-blue-background white-font">
      <Container className="py-4">
        <Row>
          <Col sm="auto" className="align-self-center">
            <img
              width="209"
              height="55"
              src="/assets/images/omnes-logo.svg"
              alt="Omnes logo"
            />
          </Col>
          <Col sm className="mt-3 mt-md-0">
            <p className="gray-font mb-0 l-bold">
              OMNES Education est une institution privée d’enseignement
              supérieur et de recherche interdisciplinaire, implantée à Beaune,
              Bordeaux, Chambéry, Lyon, Rennes et Paris. Avec ses campus à
              Abidjan, Barcelone, Genève, Londres, Monaco, Munich, Montreux et
              San Francisco, OMNES Education occupe une place unique dans le
              paysage éducatif français.
            </p>
          </Col>
        </Row>
        <Row className="mt-4">
          {stats.map((stat, index) => (
            <Col md={4} xl className="mt-1" key={index}>
              <div className="d-flex align-items-center align-items-md-start">
                <h3 className="me-2 mb-0 border-start ps-2 h-fit h2">
                  {stat.number}
                </h3>
                <span className="mt-md-2 m-regular">{stat.text}</span>
              </div>
            </Col>
          ))}
        </Row>
        <Row className="mt-4 gray-font l-bold">
          {category.map((field, index) => (
            <React.Fragment key={index}>
              <Col className="d-flex align-items-center my-1">
                <span>{field}</span>
                {index < category.length - 1 && <span className="mx-3">∣</span>}
              </Col>
            </React.Fragment>
          ))}
        </Row>
        <DstButton
          variant="light"
          btnClass="l-bold mt-4 d-flex"
          value="Aller sur le site OMNES Education"
          clickFunction={() =>
            window.open("https://www.omneseducation.com/", "_blank")
          }
          btnImageAfter={
            <i className="dst-icon-arrow-narrow-right ms-2 mt-1 fw-bold" />
          }
        />
      </Container>
    </div>
  );
};

export default PreFooterOmnes;
