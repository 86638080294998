export const formationData = [
  {
    imageSrc: "/assets/images/home/automobile.png",
    imageTitle: "Automobile",
    roles: [
      "Software Developer",
      "System Engineer",
      "Lighting",
      "Liaison au sol",
    ],
  },
  {
    imageSrc: "/assets/images/home/nucleaire.png",
    imageTitle: "Nucleaire",
    roles: [
      "Ingénieur Sureté Nucléaire",
      "Ingénieur fonctionnement nucléaire",
      "Ingénieur qualité nucléaire",
    ],
  },
  {
    imageSrc: "/assets/images/home/ferroviaire.png",
    imageTitle: "Ferroviaire",
    roles: ["Ingénieur Système Ferroviaire"],
  },
  {
    imageSrc: "/assets/images/home/aeronautique.png",
    imageTitle: "Aéronautique",
    roles: ["Concepteur systèmes embarqués", "Technicien banc d’essai"],
  },
  {
    imageSrc: "/assets/images/home/navale.png",
    imageTitle: "Navale",
    roles: [
      "Dessinateur construction navale",
      "Dessinateur projeteur tuyauterie",
    ],
  },
];
