import React from "react";
import DstNavbar from "../../components/DstNavbar/DstNavbar";
import { Col, Container, Row } from "react-bootstrap";
import PreFooterOmnes from "../../components/PreFooterOmnes/PreFooterOmnes";
import DstFooter from "../../components/DstFooter/DstFooter";
import Title from "../../components/DstTitle/DstTitle";
import HubSpotMeetingIframe from "../../components/HubspotMeeting/HubspotMeeting";
import HeaderContent from "../../components/HeaderContent/HeaderContent";
import DstButton from "../../components/DstButton/DstButton";
import InfoCard from "../../components/InfoCard/InfoCard";
import "./Financement.scss";
import FormationsSection from "../../components/FormationsSection/FormationsSection";
import ListItem from "../../components/DstIcon/ListItem";
import PartnerLogo from "../../components/Partnerlogo/PartnerLogo";

const Financement = () => {
  const partners = [
    { name: "Thales", logo: "thales.png" },
    { name: "Atos", logo: "atos.png" },
    { name: "Capgemini Invent", logo: "capgemini-invent.png" },
    { name: "CGI", logo: "cgi.png" },
    { name: "Inetum", logo: "inetum.png" },
    { name: "Jems", logo: "jems.png" },
    { name: "Onepoint", logo: "onepoint.png" },
  ];

  return (
    <React.Fragment>
      <DstNavbar />
      <HeaderContent
        image="/assets/images/header-img/construction.png"
        content={
          <React.Fragment>
            <Title
              text="POEI"
              color="#2424ff"
              tag="h1"
              className="h3 mt-4 mt-md-5"
              underlineWidth={100}
              textUppercase
            />
            <div className="xxl-regular">
              <span className="blue-font">Recrutez</span> gratuitement des
              profils qualifiés et expérimentés pour palier vos besoins de
              recrutement !
            </div>
            <DstButton
              variant="secondary"
              value="devenir partenaire"
              btnClass="my-4"
            />
          </React.Fragment>
        }
      />
      <Container>
        <Title
          text={
            <React.Fragment>
              Ils nous font <span className="blue-font">confiance</span>
            </React.Fragment>
          }
          tag="h2"
          underlineWidth={40}
        />
        <PartnerLogo partners={partners} />
      </Container>
      <div className="dark-background white-font py-3 py-md-5">
        <Container className="my-3 my-md-5">
          <Title
            text={
              <React.Fragment>
                <span className="green-font">La POEI</span>, ça fonctionne
                comment ?
              </React.Fragment>
            }
            tag="h2"
            color="white"
            underlineColor="#9D9FAB"
          />
          <Row>
            <Col sm={8} className="d-flex flex-column justify-content-center">
              <p className="mb-0">
                Nous vous présentons des profils qui répondent exactement à vos
                critères de recrutement dans le cadre de job dating. Nous les
                formons par la suite au métier cible qui vous intéresse. Dans le
                cadre de la POEI, la formation est intégralement prise en charge
                par le France Travail. Nous vous garantissons donc un
                recrutement de profils qualifiés, certifiés et 100%
                opérationnels.
              </p>
            </Col>
            <Col sm={4} className="text-center text-sm-end mt-2 mt-sm-0">
              <img
                src="/assets/images/certfication/france-travail.svg"
                alt="France Travail"
                className="img-fluid"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="py-3 py-md-5">
        <Title
          text={
            <React.Fragment>
              Comment s’organise le{" "}
              <span className="blue-font">recrutement</span>
            </React.Fragment>
          }
          tag="h2"
          underlineWidth={40}
        />
        <div className="d-flex scrollable">
          <Col>
            <div className="pe-2 me-2 position-relative">
              <InfoCard
                title={"Définir les critères"}
                fourColumn={false}
                customClass="frise-recrutement d-flex justify-content-center align-items-center"
                bgColor="#2424FF"
                textColor="white"
              />
              <i className="dst-icon-chevron-right position-absolute top-50 start-100 translate-middle" />
            </div>
            <div className="xs-regular pe-2 me-2 mt-2">
              <strong>Réunion d’échange</strong> pour définir vos objectifs de
              recrutement
            </div>
          </Col>
          <Col>
            <div className="pe-2 me-2 position-relative">
              <InfoCard
                title={"Job Dating"}
                fourColumn={false}
                customClass="frise-recrutement d-flex justify-content-center align-items-center"
              />
              <i className="dst-icon-chevron-right position-absolute top-50 start-100 translate-middle" />
            </div>
            <div className="xs-regular pe-2 me-2 mt-2">
              <strong>Présentation</strong> des profils présélectionnés
              répondant à vos critères de recrutement & ayant réussi leur test
              de positionnement dans le cadre d’un job dating dédié
            </div>
          </Col>
          <Col>
            <div className="pe-2 me-2 position-relative">
              <InfoCard
                title={"Démarche France Travail"}
                fourColumn={false}
                customClass="frise-recrutement d-flex justify-content-center align-items-center"
              />
              <i className="dst-icon-chevron-right position-absolute top-50 start-100 translate-middle" />
            </div>
            <div className="xs-regular pe-2 me-2 mt-2">
              <strong>Inscription</strong> en formation des profils sélectionnés
              - Envoi des promesses d’embauche pour les candidats sélectionnés
              (Durée du process : 5 jours)
            </div>
          </Col>
          <Col>
            <div className="pe-2 me-2 position-relative">
              <InfoCard
                title={"Entrée en formation"}
                fourColumn={false}
                customClass="frise-recrutement d-flex justify-content-center align-items-center"
              />
              <i className="dst-icon-chevron-right position-absolute top-50 start-100 translate-middle" />
            </div>
            <div className="xs-regular pe-2 me-2 mt-2">
              <strong>3 mois de formation intensive</strong> débouchant sur un
              CDI - Préparation au passage du Certification Cloud de niveau
              Expert
            </div>
          </Col>
          <Col className="position-relative">
            <InfoCard
              title={"Signature du CDI"}
              fourColumn={false}
              customClass="frise-recrutement d-flex justify-content-center align-items-center"
              bgColor="#20FFA4"
            />
          </Col>
        </div>
      </Container>
      <div className="dark-background white-font py-3 py-md-5">
        <Container>
          <Title
            color="white"
            underlineColor="#9D9FAB"
            underlineWidth={50}
            tag="h2"
            text={
              <React.Fragment>
                Découvrez nos formations en{" "}
                <span className="green-font">Ingénierie</span>
              </React.Fragment>
            }
          />
          <FormationsSection textColor={"#F7F7F7"} />
        </Container>
      </div>
      <Container className="py-3 my-md-5">
        <Title
          text={
            <React.Fragment>
              Pourquoi <span className="blue-font">DST Ingénierie ?</span>
            </React.Fragment>
          }
        />
        <Row className="mt-3 mt-md-5">
          <InfoCard
            title="Définition du besoin"
            text="DataScientest identifie les besoins spécifiques des entreprises et détermine les profils à sourcer, ainsi que la formation adaptée à ces profils."
            imageSrc="/assets/images/icon/write.svg"
            borderColor={"#0A0E29"}
          />
          <InfoCard
            title="Sourcing"
            text="En accord avec les critères de sélection, DataScientest identifie
            et sélectionne les profils les plus proches des besoins
            spécifiques de l’entreprise. Ces profils bénéficieront d’une
            formation ciblée pour combler leurs lacunes."
            imageSrc="/assets/images/icon/light.svg"
            borderColor={"#0A0E29"}
          />
          <InfoCard
            title="Formation"
            text="Une fois les profils sélectionnés et validés par l’entreprise, ils
            entament une formation intensive d’une durée maximale de 11
            semaines. À l’issue de cette formation, ils seront pleinement
            qualifiés et certifiés, prêts à répondre aux besoins de
            l’entreprise."
            imageSrc="/assets/images/icon/book.svg"
            borderColor={"#0A0E29"}
          />
          <InfoCard
            title="Validation et embauche"
            text="La validation de la formation, et donc de l’embauche, est soumise
            à une soutenance à laquelle les équipes de l’entreprise sont
            invitées à assister. La décision finale d’embauche repose
            entièrement entre les mains de l’entreprise cliente."
            imageSrc="/assets/images/icon/work.svg"
            borderColor={"#0A0E29"}
          />
        </Row>
      </Container>
      <div className="dark-background white-font py-3 py-md-5">
        <Container>
          <Row>
            <Col md={7} className="d-flex flex-column">
              <Title
                underlineColor="#9D9FAB"
                underlineWidth={40}
                className="mb-md-4"
                color="white"
                tag="h2"
                text={
                  <React.Fragment>
                    Quelques chiffres <span className="green-font">clés</span>
                  </React.Fragment>
                }
              />
              <ul className="list-unstyled">
                <li className="mb-2">
                  <ListItem color="#20FFA4" className="me-2" />
                  Plus de 3 millions de visiteurs annuels sur notre site et de
                  nombreux médias partenaires
                </li>
                <li className="mb-2">
                  <ListItem color="#20FFA4" className="me-2" />
                  Plus de 100 000 abonnés à notre Newsletter
                </li>
                <li className="mb-2">
                  <ListItem color="#20FFA4" className="me-2" />
                  Une équipe dédiée au screening des CV Un test d'éligibilité
                  pour valider les pré-requis
                </li>
                <li className="mb-2">
                  <ListItem color="#20FFA4" className="me-2" />
                  Plus de 1000 entretiens réalisés par mois par nos équipes de
                  conseillers pédagogiques
                </li>
              </ul>
            </Col>
            <Col md className="text-md-end">
              <img
                src="/assets/images/header-img/industry-engineer.png"
                alt="industry engineer"
                className="img-fluid"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className="mt-3 mt-md-5">
          <Col lg={7}>
            <Title
              className="h3"
              tag="h2"
              text="Contactez-nous"
              color="blue"
              underlineWidth={60}
            />
            <div className="s-regular h2">
              Vous êtes <span className="blue-font">intéressé·e</span> ?
            </div>
            <HubSpotMeetingIframe
              source={"https://meetings.hubspot.com/joseph-allouche?embed=true"}
            />
          </Col>
          <Col lg className="d-flex align-self-center px-0 d-none d-lg-block">
            <img
              src="/assets/images/contact.png"
              alt="contact"
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>
      <PreFooterOmnes />
      <DstFooter />
    </React.Fragment>
  );
};

export default Financement;
