import React, { useEffect, useState } from "react";

import "./YouTubeVideo.scss";

const YouTubeVideo = ({
  videoId,
  widthPercentage = 100,
  mobileWidthPercentage = widthPercentage,
  center = false,
  decoration = false,
}: {
  videoId: string;
  widthPercentage?: number;
  mobileWidthPercentage?: number;
  center?: boolean;
  decoration?: boolean;
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (decoration) {
    center = true;
    widthPercentage = Math.min(widthPercentage, 90);
    mobileWidthPercentage = Math.min(mobileWidthPercentage, 90);
  }

  const sizeStyle = {
    width: `${isMobile ? mobileWidthPercentage : widthPercentage}%`,
    height: `${
      ((isMobile ? mobileWidthPercentage : widthPercentage) * 9) / 16
    }%`,
  };
  const decorationWidthStyle = {
    width: `calc(${isMobile ? mobileWidthPercentage : widthPercentage}% + 10%)`,
  };

  return (
    <div
      className={`video-container position-relative
      ${center && " text-center"}
      ${decoration && "decoration-video"}`}
    >
      <iframe
        className={`video-iframe embed-responsive-item position-relative z-1 ${
          decoration && "my-3 my-md-5 mx-md-5"
        }`}
        style={sizeStyle}
        title="YouTube video player"
        src={`https://www.youtube.com/embed/${videoId}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      {decoration && (
        <img
          src="/assets/images/decoration/deco-video.svg"
          alt="decoration"
          className="position-absolute top-50 start-50 translate-middle z-0"
          style={decorationWidthStyle}
        />
      )}
    </div>
  );
};

export default YouTubeVideo;
