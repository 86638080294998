import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";

import DstNavbar from "../../../components/DstNavbar/DstNavbar";
import HeaderContent from "../../../components/HeaderContent/HeaderContent";
import DstProgram from "../../../components/DstProgram/DstProgram";
import InfoCard from "../../../components/InfoCard/InfoCard";
import DstButton from "../../../components/DstButton/DstButton";
import DstFooter from "../../../components/DstFooter/DstFooter";
import PreFooterOmnes from "../../../components/PreFooterOmnes/PreFooterOmnes";
import Title from "../../../components/DstTitle/DstTitle";
import Popup from "../../../components/Popup/Popup";
import HubspotForm from "../../../components/HubspotForm/HubspotForm";
import { ContactSection } from "../Formation.component";

import "../Formation.scss";

const LiaisonSol = () => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleShow = () => {
    setShowModal(true);
  };

  const liaisonSolProgram = [
    {
      title: "Découverte du monde de l'automobile (Produit/process)",
      img: "/assets/images/icon/rule.svg",
    },
    {
      title: "Architecture Véhicule Thermique / Electrique /Hybride",
      img: "/assets/images/icon/rule.svg",
    },
    {
      title: "Architecture d'ouvrants Modulaire",
      img: "/assets/images/icon/tuyaux.svg",
    },
    {
      title: "Animation de réunion",
      img: "/assets/images/icon/work.svg",
    },
    {
      title: "Prise de parole",
      img: "/assets/images/icon/rule.svg",
    },
    {
      title: "Rédaction de synthèse",
      img: "/assets/images/icon/fiche.svg",
    },
    {
      title: "Management de projet",
      img: "/assets/images/icon/work-women.svg",
    },
    {
      title: "Planning Constructeurs & Equipementiers (V3P +PIV)",
      img: "/assets/images/icon/redaction.svg",
    },
    {
      title: "Logiciels de planification et de gestion  JIRA",
      img: "/assets/images/icon/rule.svg",
    },
    {
      title: "Logiciels de planification et de gestionMS PROJECT",
      img: "/assets/images/icon/fiche.svg",
    },
    {
      title: "Technologies des mécanismes d'ouvrants(Serrure, Câble, COE, COI)",
      img: "/assets/images/icon/balance.svg",
    },
    {
      title: "Etanchéité et vitrage",
      img: "/assets/images/icon/fiche.svg",
    },
    {
      title: "Emboutissage (Acier / Alu)",
      img: "/assets/images/icon/network-card.svg",
    },
    {
      title: "Injection plastique / Rhéologie",
      img: "/assets/images/icon/component-card.svg",
    },
    {
      title:
        "Géometrie Cotation / Iso statisme /Dispersion / Réglage (MAP) Chaine de cote Qualité Percue",
      img: "/assets/images/icon/component-card.svg",
    },
    {
      title: "Acoustique",
      img: "/assets/images/icon/balance.svg",
    },
    {
      title: "Réglementation Auto (charnière , serrure, crash...)",
      img: "/assets/images/icon/fiche.svg",
    },
    {
      title: "Choc latéral, pièton, arrière",
      img: "/assets/images/icon/network-card.svg",
    },
    {
      title: "Eclairage intérieur et Performance Auto",
      img: "/assets/images/icon/component-card.svg",
    },
    {
      title: "Eclairage extérieur (répétiteur)",
      img: "/assets/images/icon/component-card.svg",
    },
    {
      title: "Rétrovision",
      img: "/assets/images/icon/redaction.svg",
    },
    {
      title:
        "Electrique / Electronique Câblage MAP Elec (Ex : porte sans cadre)",
      img: "/assets/images/icon/rule.svg",
    },
    {
      title: "Design (tous les types d'ouvrant)",
      img: "/assets/images/icon/fiche.svg",
    },
    {
      title: "Excel TCD",
      img: "/assets/images/icon/balance.svg",
    },
    {
      title: "Power Point (animation)",
      img: "/assets/images/icon/fiche.svg",
    },
    {
      title: "Construction d'un plan de validation",
      img: "/assets/images/icon/network-card.svg",
    },
    {
      title: "Construction d'un plan d'homologation",
      img: "/assets/images/icon/rule.svg",
    },
    {
      title: "Dimensionnement de l'ensemble Ouvrant",
      img: "/assets/images/icon/fiche.svg",
    },
    {
      title: "PLM",
      img: "/assets/images/icon/balance.svg",
    },
    {
      title: "CATIA V5",
      img: "/assets/images/icon/fiche.svg",
    },
    {
      title: "3D expérience",
      img: "/assets/images/icon/network-card.svg",
    },
    {
      title: "Immersion projet",
      img: "/assets/images/icon/component-card.svg",
    },
  ];

  return (
    <React.Fragment>
      <DstNavbar />
      <HeaderContent
        alt="automobile"
        image="/assets/images/home/automobile.png"
        title={
          <h1>
            Formez vos futurs collaborateurs au secteur de l’automobile en tant
            que <span className="blue-font">Liaison au sol</span>
          </h1>
        }
        content={
          <div className="mt-3">
            <p>
              Grâce au partenariat entre DataScientest et SEGULA Technologies,
              rejoignez un groupe d'ingénierie mondiale à forte croissance en
              tant que System Engineer, après une formation accélérée de 11
              semaines.
            </p>
            <p>
              Impression 3D, réalité augmentée, véhicule autonome, usine du
              futur... rythment le quotidien de nos 13 000 ingénieux
              collaborateurs, pourquoi pas le vôtre ?
            </p>
          </div>
        }
        dstButton={{
          value: "J’envoie mon cv",
          clickFunction: handleShow,
        }}
      />

      <Container className="my-3 my-md-5">
        <Title text="Automobile" center underlineWidth={30} textUppercase />
        <Title
          text="Le cursus"
          tag="h2"
          className="h3"
          color="#2424FF"
          underlineWidth={10}
          textUppercase
        />
        <DstProgram
          title={
            <React.Fragment>
              Découvrez notre formation en{" "}
              <span className="blue-font">Parcours Liaison au sol</span>
            </React.Fragment>
          }
          items={liaisonSolProgram}
          dstButton={{
            value: "Recevoir le document",
            clickFunction: handleShow,
            variant: "secondary",
          }}
        />
      </Container>
      <div className="dark-background white-font py-3 py-md-5">
        <Container className="my-3 my-md-5">
          <Title
            text="Recrutement"
            className="h3"
            tag="h2"
            color="#20FFA4"
            underlineColor="#9D9FAB"
            textUppercase
          />
          <h3 className="h2 mb-3 mb-md-5">
            Comment s’organise le{" "}
            <span className="green-font">recrutement</span>
          </h3>
          <Row>
            <InfoCard
              title="Définition du besoin"
              text="DataScientest identifie les besoins spécifiques des entreprises et détermine les profils à sourcer, ainsi que la formation adaptée à ces profils."
              imageSrc="/assets/images/icon/write.svg"
              borderColor={"#F7F7F7"}
            />
            <InfoCard
              title="Sourcing"
              text="En accord avec les critères de sélection, DataScientest identifie
            et sélectionne les profils les plus proches des besoins
            spécifiques de l’entreprise. Ces profils bénéficieront d’une
            formation ciblée pour combler leurs lacunes."
              imageSrc="/assets/images/icon/light.svg"
              borderColor={"#F7F7F7"}
            />
            <InfoCard
              title="Formation"
              text="Une fois les profils sélectionnés et validés par l’entreprise, ils
            entament une formation intensive d’une durée maximale de 11
            semaines. À l’issue de cette formation, ils seront pleinement
            qualifiés et certifiés, prêts à répondre aux besoins de
            l’entreprise."
              imageSrc="/assets/images/icon/book.svg"
              borderColor={"#F7F7F7"}
            />
            <InfoCard
              title="Validation et embauche"
              text="La validation de la formation, et donc de l’embauche, est soumise
            à une soutenance à laquelle les équipes de l’entreprise sont
            invitées à assister. La décision finale d’embauche repose
            entièrement entre les mains de l’entreprise cliente."
              imageSrc="/assets/images/icon/work.svg"
              borderColor={"#F7F7F7"}
            />
          </Row>
          <div className="text-center">
            <DstButton
              value="télécharger la brochure"
              btnClass="m-bold"
              clickFunction={handleShow}
            />
          </div>
        </Container>
      </div>
      <Container className="mt-3 mt-md-5">
        <ContactSection
          portalId="19831339"
          formId="2589b2c6-f76c-4ffc-b585-2266b7f6f831"
        />
      </Container>
      <PreFooterOmnes />
      <DstFooter />

      <Popup
        show={showModal}
        onClose={handleClose}
        modalTitle="Syllabus Liaison SQl"
      >
        <div>
          <HubspotForm
            portalId="19831339"
            formId="2589b2c6-f76c-4ffc-b585-2266b7f6f831"
          />
        </div>
      </Popup>
    </React.Fragment>
  );
};

export default LiaisonSol;
