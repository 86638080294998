import React from "react";

import "./ImgDecoration.scss";

const ImgDecoration = ({
  title,
  imageUrl,
  height,
  showBarTop = true,
  showBarBottom = true,
  showBarLeft = true,
  showBarRight = true,
  bgColor = "#000",
}: {
  title: string;
  imageUrl: string;
  height: number;
  showBarTop?: boolean;
  showBarBottom?: boolean;
  showBarLeft?: boolean;
  showBarRight?: boolean;
  bgColor?: string;
}) => {
  return (
    <div className="position-relative">
      <img src={imageUrl} alt={title} height={height} width={height} />
      {showBarTop && (
        <div className="bar-1" style={{ backgroundColor: bgColor }}></div>
      )}
      {showBarBottom && (
        <div className="bar-2" style={{ backgroundColor: bgColor }}></div>
      )}
      {showBarLeft && (
        <div className="bar-3" style={{ backgroundColor: bgColor }}></div>
      )}
      {showBarRight && (
        <div className="bar-4" style={{ backgroundColor: bgColor }}></div>
      )}
    </div>
  );
};

export default ImgDecoration;
