import React from "react";

import "./DstTitle.scss";

const Title = ({
  className = "",
  center = false,
  text,
  color = "#000",
  tag: Tag = "h1",
  textUppercase = false,
  underlineEnabled = true,
  underlineWidth = 20,
  underlineColor = "#000",
  renderUnderline = () => (
    <div
      className={`underline mt-2 d-flex align-items-center position-relative ${
        center && "mx-auto"
      }`}
      style={{ width: `${underlineWidth}%` }}
    >
      <div className="long-bar" style={{ backgroundColor: underlineColor }} />
      <div
        className="short-bar-first position-absolute"
        style={{ backgroundColor: underlineColor }}
      />
      <div
        className="short-bar-second position-absolute"
        style={{ backgroundColor: underlineColor }}
      />
    </div>
  ),
}: {
  className?: string;
  center?: boolean;
  text: string | JSX.Element;
  color?: string;
  tag?: "h1" | "h2" | "h3" | "h4" | "h5" | "div";
  textUppercase?: boolean;
  underlineEnabled?: boolean;
  underlineWidth?: number;
  underlineColor?: string;
  renderUnderline?: () => React.ReactNode;
}) => {
  return (
    <Tag
      className={`${className} ${center ? "text-center" : ""} ${
        textUppercase ? "text-uppercase" : ""
      }`}
      style={{ color: color }}
    >
      {text}
      {underlineEnabled && renderUnderline()}
    </Tag>
  );
};

export default Title;
