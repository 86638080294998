import React, { useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

declare global {
  interface Window {
    hbspt: any;
  }
}

const generateUniqueId = (formId: string) => {
  return `hubspotForm-${formId}-${uuidv4()}`;
};

const HubspotForm = ({
  portalId,
  formId,
}: {
  portalId: string;
  formId: string;
}) => {
  const formRef = useRef<string>(generateUniqueId(formId));

  useEffect(() => {
    const scriptId = `hs-forms-script-${formRef.current}`;
    const targetId = formRef.current;

    function loadForm() {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId,
          formId,
          target: `#${targetId}`,
        });
      }
    }

    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    script.id = scriptId;
    document.body.appendChild(script);

    script.onload = loadForm;

    return () => {
      script.remove();
    };
  }, [portalId, formId]);

  return (
    <div>
      <div id={formRef.current}></div>
    </div>
  );
};

export default HubspotForm;
