import React from "react";

const ListItem = ({
  width = "1em",
  height = "1em",
  color = "#2424FF",
  className = "me-2",
}: {
  width?: string;
  height?: string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.5 1L13.5 11" stroke={color} strokeLinecap="round" />
      <path d="M1 6H13" stroke={color} strokeLinecap="round" />
    </svg>
  );
};

export default ListItem;
