import React, { useState } from "react";
import DstNavbar from "../../../components/DstNavbar/DstNavbar";
import PreFooterOmnes from "../../../components/PreFooterOmnes/PreFooterOmnes";
import DstFooter from "../../../components/DstFooter/DstFooter";
import HeaderContent from "../../../components/HeaderContent/HeaderContent";
import { Container, Row } from "react-bootstrap";
import Title from "../../../components/DstTitle/DstTitle";
import DstProgram from "../../../components/DstProgram/DstProgram";
import InfoCard from "../../../components/InfoCard/InfoCard";
import DstButton from "../../../components/DstButton/DstButton";
import { ContactSection } from "../Formation.component";
import Popup from "../../../components/Popup/Popup";
import HubspotForm from "../../../components/HubspotForm/HubspotForm";

const IngenieurSystemeFerroviaire = () => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleShow = () => {
    setShowModal(true);
  };

  const ingenieurSystemFerroviaireProgram = [
    {
      title: "Animation de réunion",
      img: "/assets/images/icon/rule.svg",
    },
    {
      title: "Rédaction de synthèse",
      img: "/assets/images/icon/tuyaux.svg",
    },
    {
      title: "Prise de parole",
      img: "/assets/images/icon/work.svg",
    },
    {
      title: "Management de projet",
      img: "/assets/images/icon/rule.svg",
    },
    {
      title:
        "Développer les exigences / Comprendre les besoins et spécifier le produit",
      img: "/assets/images/icon/fiche.svg",
    },
    {
      title: "Gérer les exigences et les demandes de changements",
      img: "/assets/images/icon/work-women.svg",
    },
    {
      title: "MBSE",
      img: "/assets/images/icon/redaction.svg",
    },
    {
      title: "Le transport ferroviaire et guidé",
      img: "/assets/images/icon/rule.svg",
    },
    {
      title: "SYSML",
      img: "/assets/images/icon/fiche.svg",
    },
    {
      title: "TS 22163",
      img: "/assets/images/icon/balance.svg",
    },
    {
      title: "EN 50128",
      img: "/assets/images/icon/fiche.svg",
    },
    {
      title: "EN 50126",
      img: "/assets/images/icon/network-card.svg",
    },
    {
      title: "EN 50129",
      img: "/assets/images/icon/component-card.svg",
    },
    {
      title: "Présenter ses nouvelles compétences",
      img: "/assets/images/icon/setting.svg",
    },
    {
      title: "Projet final",
      img: "/assets/images/icon/light.svg",
    },
  ];

  return (
    <React.Fragment>
      <DstNavbar />
      <HeaderContent
        alt="ferroviaire"
        image="/assets/images/home/ferroviaire.png"
        title={
          <h1 className="fw-normal">
            Formez vos futurs collaborateurs au secteur Ferroviaire en tant que{" "}
            <span className="fw-black">Ingénieur Système Ferroviaire</span>
          </h1>
        }
        content={
          <div className="mt-3">
            <p>
              Grâce au partenariat entre DataScientest et SEGULA Technologies,
              rejoignez un groupe d'ingénierie mondiale à forte croissance en
              tant qu' Ingénieur Système Ferroviaire, après une formation
              accélérée de 11 semaines.
            </p>
            <p>
              Impression 3D, réalité augmentée, véhicule autonome, usine du
              futur... rythment le quotidien de nos 13 000 ingénieux
              collaborateurs, pourquoi pas le vôtre ?
            </p>
          </div>
        }
        dstButton={{
          value: "J’envoie mon cv",
          clickFunction: handleShow,
        }}
      />

      <Container className="my-3 my-md-5">
        <Title text="Ferroviaire" center underlineWidth={30} textUppercase />
        <Title
          text="Le cursus"
          tag="h2"
          className="h3"
          color="#2424FF"
          underlineWidth={10}
          textUppercase
        />
        <DstProgram
          title={
            <React.Fragment>
              Découvrez notre formation en{" "}
              <span className="blue-font">
                Dessinateur Construction Navale Système Ferroviaire
              </span>
            </React.Fragment>
          }
          items={ingenieurSystemFerroviaireProgram}
          dstButton={{
            value: "Recevoir le document",
            clickFunction: handleShow,
            variant: "secondary",
          }}
        />
      </Container>
      <div className="dark-background white-font py-3 py-md-5">
        <Container className="my-3 my-md-5">
          <Title
            text="Recrutement"
            className="h3"
            tag="h2"
            color="#20FFA4"
            underlineColor="#9D9FAB"
            textUppercase
          />
          <h3 className="h2 mb-3 mb-md-5">
            Comment s’organise le{" "}
            <span className="green-font">recrutement</span>
          </h3>
          <Row>
            <InfoCard
              title="Définition du besoin"
              text="DataScientest identifie les besoins spécifiques des entreprises et détermine les profils à sourcer, ainsi que la formation adaptée à ces profils."
              imageSrc="/assets/images/icon/write.svg"
              borderColor={"#F7F7F7"}
            />
            <InfoCard
              title="Sourcing"
              text="En accord avec les critères de sélection, DataScientest identifie
            et sélectionne les profils les plus proches des besoins
            spécifiques de l’entreprise. Ces profils bénéficieront d’une
            formation ciblée pour combler leurs lacunes."
              imageSrc="/assets/images/icon/light.svg"
              borderColor={"#F7F7F7"}
            />
            <InfoCard
              title="Formation"
              text="Une fois les profils sélectionnés et validés par l’entreprise, ils
            entament une formation intensive d’une durée maximale de 11
            semaines. À l’issue de cette formation, ils seront pleinement
            qualifiés et certifiés, prêts à répondre aux besoins de
            l’entreprise."
              imageSrc="/assets/images/icon/book.svg"
              borderColor={"#F7F7F7"}
            />
            <InfoCard
              title="Validation et embauche"
              text="La validation de la formation, et donc de l’embauche, est soumise
            à une soutenance à laquelle les équipes de l’entreprise sont
            invitées à assister. La décision finale d’embauche repose
            entièrement entre les mains de l’entreprise cliente."
              imageSrc="/assets/images/icon/work.svg"
              borderColor={"#F7F7F7"}
            />
          </Row>
          <div className="text-center">
            <DstButton
              value="télécharger la brochure"
              btnClass="m-bold"
              clickFunction={handleShow}
            />
          </div>
        </Container>
      </div>
      <Container className="mt-3 mt-md-5">
        <ContactSection
          portalId="19831339"
          formId="d84e0aa7-295d-44bf-9bb5-8b183eb59cbb"
        />
      </Container>
      <PreFooterOmnes />
      <DstFooter />

      <Popup
        show={showModal}
        onClose={handleClose}
        modalTitle="Syllabus Liaison SQl"
      >
        <div>
          <HubspotForm
            portalId="19831339"
            formId="d84e0aa7-295d-44bf-9bb5-8b183eb59cbb"
          />
        </div>
      </Popup>
    </React.Fragment>
  );
};

export default IngenieurSystemeFerroviaire;
