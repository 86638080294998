import React from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";

import DstButton from "../../components/DstButton/DstButton";
import DstFooter from "../../components/DstFooter/DstFooter";
import DstNavbar from "../../components/DstNavbar/DstNavbar";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <DstNavbar />
      <Container className="text-center content d-flex flex-column justify-content-center align-items-center">
        <h1>Page not found</h1>
        <DstButton
          btnClass="mt-5"
          value="Back to home"
          variant="secondary"
          clickFunction={() => navigate("/")}
        />
      </Container>
      <DstFooter />
    </React.Fragment>
  );
};

export default NotFound;
