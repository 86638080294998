import React from "react";
import Modal from "react-bootstrap/Modal";

import "./Popup.scss";

interface PopupProps {
  children: JSX.Element[] | JSX.Element;
  onClose: () => void;
  modalTitle?: string;
  size?: "xl" | "lg" | "sm";
  fullscreen?: string | true;
  show: boolean;
  scrollable?: boolean;
}

const Popup = ({
  children,
  onClose,
  modalTitle,
  size = "lg",
  fullscreen,
  show,
  scrollable = false,
}: PopupProps) => {
  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={onClose}
        size={size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        fullscreen={fullscreen}
        scrollable={scrollable}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Popup;
