import React from "react";
import { Link } from "react-router-dom";
import "./DstSlider.scss";

interface Slide {
  imageUrl: string;
  text: string;
  link: string;
}

interface ImageSliderProps {
  slides: Slide[];
  height?: string;
  className?: string;
}

const DstSlider = ({
  slides,
  height = "200px",
  className = "my-3 my-md-5",
}: ImageSliderProps) => {
  const SlideContent = ({ slide }: { slide: Slide }) => (
    <React.Fragment>
      <img
        className="slider-image"
        src={slide.imageUrl}
        alt={slide.text}
        style={{ height }}
      />
      <div className="h5 light-font position-absolute bottom-0 start-50 translate-middle-x w-100 text-center mb-3">
        {slide.text}
      </div>
    </React.Fragment>
  );

  return (
    <div className={`d-flex overflow-x-auto ${className}`}>
      {slides.map((slide, index) => (
        <div
          className={`position-relative d-inline-block ${
            index !== slides.length - 1 ? "me-3" : ""
          }`}
          key={`slider-img-${index}`}
        >
          {slide.link.startsWith("http") ? (
            <a href={slide.link} target="_blank" rel="noopener noreferrer">
              <SlideContent slide={slide} />
            </a>
          ) : (
            <Link to={slide.link}>
              <SlideContent slide={slide} />
            </Link>
          )}
        </div>
      ))}
    </div>
  );
};

export default DstSlider;
