import React from "react";
import DstNavbar from "../../components/DstNavbar/DstNavbar";
import DstFooter from "../../components/DstFooter/DstFooter";
import PreFooterOmnes from "../../components/PreFooterOmnes/PreFooterOmnes";
import { Container } from "react-bootstrap";
import HubSpotMeetingIframe from "../../components/HubspotMeeting/HubspotMeeting";

const Rdv = () => {
  return (
    <React.Fragment>
      <DstNavbar />
      <Container className="my-3 my-md-5">
        <HubSpotMeetingIframe
          source={"https://meetings.hubspot.com/joseph-allouche?embed=true"}
        />
      </Container>
      <PreFooterOmnes />
      <DstFooter />
    </React.Fragment>
  );
};

export default Rdv;
