import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";

import DstButton from "../../../components/DstButton/DstButton";
import DstFooter from "../../../components/DstFooter/DstFooter";
import DstNavbar from "../../../components/DstNavbar/DstNavbar";
import DstProgram from "../../../components/DstProgram/DstProgram";
import HeaderContent from "../../../components/HeaderContent/HeaderContent";
import HubspotForm from "../../../components/HubspotForm/HubspotForm";
import InfoCard from "../../../components/InfoCard/InfoCard";
import Popup from "../../../components/Popup/Popup";
import PreFooterOmnes from "../../../components/PreFooterOmnes/PreFooterOmnes";
import Title from "../../../components/DstTitle/DstTitle";

import { ContactSection } from "../Formation.component";

import "../Formation.scss";

const SystemEngineer = () => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleShow = () => {
    setShowModal(true);
  };

  const systemEngineerProgram = [
    {
      title: "Architecture et conception de systèmes",
      img: "/assets/images/icon/rule.svg",
    },
    {
      title: "Sécurité Systèmes et réseaux",
      img: "/assets/images/icon/tuyaux.svg",
    },
    {
      title: "Implémentation, intégration et test du système",
      img: "/assets/images/icon/work.svg",
    },
    {
      title: "ASPICE (Automotive SPICE)",
      img: "/assets/images/icon/rule.svg",
    },
    {
      title: "Les Méthodes agiles",
      img: "/assets/images/icon/fiche.svg",
    },
    {
      title: "Animation de réunion",
      img: "/assets/images/icon/work-women.svg",
    },
    {
      title: "Redaction de synthèse",
      img: "/assets/images/icon/redaction.svg",
    },
    {
      title: "Prise de parole",
      img: "/assets/images/icon/rule.svg",
    },
    {
      title: "Management de projet",
      img: "/assets/images/icon/fiche.svg",
    },
    {
      title: "Logiciels de planification et de gestion JIRA",
      img: "/assets/images/icon/balance.svg",
    },
    {
      title: "Linux / BASH",
      img: "/assets/images/icon/fiche.svg",
    },
    {
      title: "Réseaux: Ethernet / Bus CAN",
      img: "/assets/images/icon/network-card.svg",
    },
    {
      title: "Algorithmique",
      img: "/assets/images/icon/component-card.svg",
    },
    {
      title: "Debug GDB / DDD/ ASAN",
      img: "/assets/images/icon/setting.svg",
    },
    {
      title: "Matlab / Simulink",
      img: "/assets/images/icon/setting.svg",
    },
    {
      title: "SYSML",
      img: "/assets/images/icon/rule.svg",
    },
    {
      title: "Validation et Tests des Logiciels",
      img: "/assets/images/icon/fiche.svg",
    },
    {
      title: "La gestion des exigences avec DOORS",
      img: "/assets/images/icon/rule.svg",
    },
    {
      title: "Autosar (Automotive Open System Architecture)",
      img: "/assets/images/icon/setting-square.svg",
    },
    {
      title: "Projet de fin cursus",
      img: "/assets/images/icon/light.svg",
    },
  ];

  return (
    <React.Fragment>
      <DstNavbar />
      <HeaderContent
        alt="automobile"
        image="/assets/images/home/automobile.png"
        title={
          <h1>
            Formez vos futurs collaborateurs au secteur de l’automobile en tant
            que <span className="blue-font">System Engineer</span>
          </h1>
        }
        content={
          <div className="mt-3">
            <p>
              Grâce au partenariat entre DataScientest et SEGULA Technologies,
              rejoignez un groupe d'ingénierie mondiale à forte croissance en
              tant que System Engineer, après une formation accélérée de 11
              semaines.
            </p>
            <p>
              Impression 3D, réalité augmentée, véhicule autonome, usine du
              futur... rythment le quotidien de nos 13 000 ingénieux
              collaborateurs, pourquoi pas le vôtre ?
            </p>
          </div>
        }
        dstButton={{
          value: "J’envoie mon cv",
          clickFunction: handleShow,
        }}
      />
      <Container className="my-3 my-md-5">
        <Title text="Automobile" center underlineWidth={30} textUppercase />
        <Title
          text="Le cursus"
          tag="h2"
          className="h3"
          color="#2424FF"
          underlineWidth={10}
          textUppercase
        />
        <DstProgram
          title={
            <React.Fragment>
              Découvrez notre formation en{" "}
              <span className="blue-font">System Engineer</span>
            </React.Fragment>
          }
          items={systemEngineerProgram}
          dstButton={{
            value: "Recevoir le document",
            clickFunction: handleShow,
            variant: "secondary",
          }}
        />
      </Container>
      <div className="dark-background white-font py-3 py-md-5">
        <Container className="my-3 my-md-5">
          <Title
            text="Recrutement"
            className="h3"
            tag="h2"
            color="#20FFA4"
            underlineColor="#9D9FAB"
            textUppercase
          />
          <h3 className="h2 mb-3 mb-md-5">
            Comment s’organise le{" "}
            <span className="green-font">recrutement</span>
          </h3>
          <Row>
            <InfoCard
              title="Définition du besoin"
              text="DataScientest identifie les besoins spécifiques des entreprises et détermine les profils à sourcer, ainsi que la formation adaptée à ces profils."
              imageSrc="/assets/images/icon/write.svg"
              borderColor={"#F7F7F7"}
            />
            <InfoCard
              title="Sourcing"
              text="En accord avec les critères de sélection, DataScientest identifie
            et sélectionne les profils les plus proches des besoins
            spécifiques de l’entreprise. Ces profils bénéficieront d’une
            formation ciblée pour combler leurs lacunes."
              imageSrc="/assets/images/icon/light.svg"
              borderColor={"#F7F7F7"}
            />
            <InfoCard
              title="Formation"
              text="Une fois les profils sélectionnés et validés par l’entreprise, ils
            entament une formation intensive d’une durée maximale de 11
            semaines. À l’issue de cette formation, ils seront pleinement
            qualifiés et certifiés, prêts à répondre aux besoins de
            l’entreprise."
              imageSrc="/assets/images/icon/book.svg"
              borderColor={"#F7F7F7"}
            />
            <InfoCard
              title="Validation et embauche"
              text="La validation de la formation, et donc de l’embauche, est soumise
            à une soutenance à laquelle les équipes de l’entreprise sont
            invitées à assister. La décision finale d’embauche repose
            entièrement entre les mains de l’entreprise cliente."
              imageSrc="/assets/images/icon/work.svg"
              borderColor={"#F7F7F7"}
            />
          </Row>
          <div className="text-center">
            <DstButton
              value="télécharger la brochure"
              btnClass="m-bold"
              clickFunction={handleShow}
            />
          </div>
        </Container>
      </div>
      <Container className="mt-3 mt-md-5">
        <ContactSection
          portalId="19831339"
          formId="6e188b83-21e2-42ff-a28d-be4d83808bec"
        />
      </Container>
      <PreFooterOmnes />
      <DstFooter />

      <Popup
        show={showModal}
        onClose={handleClose}
        modalTitle="Syllabus System Engineer"
      >
        <div>
          <HubspotForm
            portalId="19831339"
            formId="6e188b83-21e2-42ff-a28d-be4d83808bec"
          />
        </div>
      </Popup>
    </React.Fragment>
  );
};

export default SystemEngineer;
