import React from "react";
import Title from "../../components/DstTitle/DstTitle";
import DstSlider from "../../components/DstSlider/DstSlider";
import DstNavbar from "../../components/DstNavbar/DstNavbar";
import { Container } from "react-bootstrap";
import PreFooterOmnes from "../../components/PreFooterOmnes/PreFooterOmnes";
import DstFooter from "../../components/DstFooter/DstFooter";

const slidesAutomobile = [
  {
    imageUrl: "/assets/images/secteurs/automobile/softwaredevelopper.png",
    text: "Software Developper",
    link: "/formation-software-developer",
  },
  {
    imageUrl: "/assets/images/secteurs/automobile/systemengineer.png",
    text: "System Engineer",
    link: "/formation-system-engineer",
  },
  {
    imageUrl: "/assets/images/secteurs/automobile/lighting.png",
    text: "Lighting",
    link: "/formation-lighting",
  },
  {
    imageUrl: "/assets/images/secteurs/automobile/liaisonausol.png",
    text: "Liaison au sol",
    link: "/formation-liaison-sol",
  },
];

const slidesNucleaire = [
  {
    imageUrl: "/assets/images/secteurs/nucleaire/surete-nucleaire.png",
    text: "Ingénieur Sureté Nucléaire",
    link: "/formation-ingenieur-surete-nucleaire",
  },
  {
    imageUrl: "/assets/images/secteurs/nucleaire/fonctionnement-nucleaire.png",
    text: "Ingénieur fonctionnement nucléaire",
    link: "/formation-ingenieur-fonctionnement-nucleaire",
  },
  {
    imageUrl: "/assets/images/secteurs/nucleaire/qualite-nucleaire.png",
    text: "Ingénieur qualité nucléaire",
    link: "/formation-ingenieur-qualite-nucleaire",
  },
];

const slidesFerroviaire = [
  {
    imageUrl:
      "/assets/images/secteurs/ferroviaire/ingenieur-system-ferroviaire.png",
    text: "Ingénieur Système Ferroviaire",
    link: "/formation-ingenieur-systeme-ferroviaire",
  },
];
const slidesAeronautique = [
  {
    imageUrl: "/assets/images/secteurs/aeronautique/concepteur-embarque.png",
    text: "Concepteur systèmes embarqués",
    link: "/formation-concepteur-systemes-embarques",
  },
  {
    imageUrl: "/assets/images/secteurs/aeronautique/technicien-banc-essai.png",
    text: "Technicien banc d’essai",
    link: "/formation-technicien-banc-essai",
  },
];

const slidesNavale = [
  {
    imageUrl: "/assets/images/secteurs/navale/construction-navale.png",
    text: "Dessinateur construction navale",
    link: "/formation-dessinateur-construction-navale",
  },
  {
    imageUrl: "/assets/images/secteurs/navale/projeteur-tuyauterie.png",
    text: "Dessinateur projeteur tuyauterie",
    link: "/formation-dessinateur-projeteur-tuyauterie",
  },
];
const Formations = () => {
  return (
    <React.Fragment>
      <DstNavbar />
      <Container>
        <Title text="Nos formations" className="mt-3" underlineWidth={100} />
        <Title
          text="Automobile"
          className="mt-3 mt-md-4"
          tag="h2"
          color="#2424ff"
        />
        <DstSlider slides={slidesAutomobile} className="mt-3" height="200px" />
        <Title
          text="Nucléaire"
          className="mt-3 mt-md-4"
          tag="h2"
          color="#20ffa4"
        />
        <DstSlider slides={slidesNucleaire} className="mt-3" height="200px" />
        <Title
          text="Férroviaire"
          className="mt-3 mt-md-4"
          tag="h2"
          color="#0A0E29"
        />
        <DstSlider slides={slidesFerroviaire} className="mt-3" height="200px" />
        <Title
          text="Aéronautique"
          className="mt-3 mt-md-4"
          tag="h2"
          color="#2424ff"
        />
        <DstSlider
          slides={slidesAeronautique}
          className="mt-3"
          height="200px"
        />
        <Title
          text="Navale"
          className="mt-3 mt-md-4"
          tag="h2"
          color="#9D9FAB"
        />
        <DstSlider slides={slidesNavale} className="mt-3 mb-5" height="200px" />
      </Container>
      <PreFooterOmnes />
      <DstFooter />
    </React.Fragment>
  );
};

export default Formations;
