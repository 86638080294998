import React from "react";
import { Container } from "react-bootstrap";

import DstFooter from "../../components/DstFooter/DstFooter";
import DstNavbar from "../../components/DstNavbar/DstNavbar";

const MentionsLegales = () => {
  return (
    <React.Fragment>
      <DstNavbar />
      <Container className="content d-flex flex-column justify-content-center ">
        <h1>Mentions Légales</h1>
        <p>
          L’édition du site Datascientest.com est assurée par la Société
          DataScientest au capital de 1 000 €, immatriculée 011755665975 au RCS
          de Paris sous le numéro 831 450 069 dont le siège social est situé au
          2 place de Barcelone 75016 Paris, contact@datascientest.com Représenté
          par Monsieur Tordjman Yoel. N° de TVA intracommunautaire : FR 69
          831450069. Le Directeur de la publication est Monsieur Tordjman Yoel.
          L’hébergeur du site est AMAZON WEB SERVICES EMEA SARL dont l’adresse
          est la suivante : AMAZON WEB SERVICES EMEA SARL 31 place des Corolles
          92400 Courbevoie.
        </p>
        <h2>Propriété intellectuelle</h2>
        <p>
          Les marques, logos, signes ainsi que tous les contenus du site
          (textes, images, son…) font l’objet d’une protection par le Code de la
          propriété intellectuelle et plus particulièrement par le droit
          d’auteur. Le Client doit solliciter l’autorisation préalable du site
          pour toute reproduction, publication, copie des différents contenus.
          Il s’engage à une utilisation des contenus du site dans un cadre
          strictement privé, toute utilisation à des fins commerciales et
          publicitaires est strictement interdite. Toute représentation totale
          ou partielle de ce site par quelque procédé que ce soit, sans
          l’autorisation expresse de l’exploitant du site Internet constituerait
          une contrefaçon sanctionnée par l’article L 335-2 et suivants du Code
          de la propriété intellectuelle. Il est rappelé conformément à
          l’article L122-5 du Code de propriété intellectuelle qu’un Client qui
          reproduit, copié ou publie le contenu protégé doit citer l’auteur et
          sa source.Les Clients (entreprises) ayant conclu une convention lors
          d’une formation en science de données pour leurs salariés, ont
          uniquement un droit de communication sur la collaboration entre
          DataScientest et le Client.
        </p>
      </Container>
      <DstFooter />
    </React.Fragment>
  );
};

export default MentionsLegales;
