import React from "react";
import DstNavbar from "../../components/DstNavbar/DstNavbar";
import PreFooterOmnes from "../../components/PreFooterOmnes/PreFooterOmnes";
import DstFooter from "../../components/DstFooter/DstFooter";
import { Container } from "react-bootstrap";
import DstButton from "../../components/DstButton/DstButton";

const AccueilHandicap = () => {
  return (
    <React.Fragment>
      <DstNavbar />
      <Container className="py-3">
        <h1 className="my-3 h2 text-center">
          Accueil des personnes en situation de handicap
        </h1>
        <p className="m-regular">
          Pour <strong>DataScientest</strong>, l’inclusion des publics en
          situation de handicap est essentielle. Pour cette raison, l’ensemble
          de l’équipe pédagogique travaille activement au déploiement d’un plan
          handicap.
        </p>
        <p className="mb-1">
          À ce titre, une référente handicap est nommée au sein de l’école :
        </p>
        <p className="m-regular">
          <strong> Mathilde Venchiarutti</strong>
          <br />
          <a
            href="mailto:mathilde.v@datascientest.com"
            target="_blank"
            rel="noreferrer"
            className="blue-font"
          >
            mathilde.v@datascientest.com
          </a>
          <br />
          Vous pouvez la contacter par mail pour toute information sur le sujet.
        </p>
        <p className="m-regular">
          DataScientest s’engage à accueillir les personnes en situation de
          handicap sans discrimination. Parce que l’égalité des chances ne se
          limite pas seulement au recrutement, nous donnons la possibilité aux
          candidats de nous transmettre leurs besoins spécifiques à leur
          situation de handicap dans le but de pouvoir adapter la formation si
          nous en avons la possibilité. Notre objectif est de pouvoir, au plus
          tôt, identifier les actions à mettre en œuvre pour adapter notre
          pédagogie tout en garantissant un niveau d’excellence de la formation.
          Il est primordial pour nous de permettre à l’apprenant.e la meilleure
          intégration possible au sein de l’école comme en entreprise.
        </p>
        <p className="m-regular">
          Concrètement, cette adaptation peut par exemple mener à une majoration
          du temps alloué aux évaluations, les apprenants peuvent ainsi
          bénéficier d’un tiers-temps lors des examens. Par ailleurs, l’ensemble
          de nos temps synchrones sont disponibles sous-titrés. L’assistance
          d’une tierce personne peut également être mise en place. En outre, par
          la nature totalement en ligne de notre offre, les candidats dans
          l’incapacité de se déplacer peuvent suivre la formation de la même
          manière que leurs collègues.
        </p>
        <p className="m-regular">
          Une adaptation du parcours peut, toutefois, demander du temps. Il est
          donc important de commencer la démarche d’adaptation dès la
          candidature afin d’apporter une réponse individualisée et adaptée.
        </p>
        <div className="d-flex justify-content-center mt-md-4">
          <p className="text-center l-bold fw-bold col-lg-8">
            Si vous êtes dans cette situation, n’hésitez pas à vous manifester
            au plus tôt auprès de nos équipes afin que l‘adaptation de votre
            formation en fonction de votre handicap puisse se faire dans les
            meilleures conditions.
          </p>
        </div>
        <div className="text-center">
          <DstButton
            btnClass="mt-1"
            disableAnimation
            variant="secondary"
            value="Contacter notre réferente handicap"
            clickFunction={() =>
              (window.location.href = "mathilde.v@datascientest.com")
            }
          />
        </div>
      </Container>

      <PreFooterOmnes />
      <DstFooter />
    </React.Fragment>
  );
};

export default AccueilHandicap;
