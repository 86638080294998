import React from "react";
import { Col } from "react-bootstrap";
import "./InfoCard.scss";

interface BorderCardProps {
  type: "horizontal" | "vertical";
  position: "top" | "bottom" | "left" | "right";
  borderColor: string;
}

const BorderCard = ({ type, position, borderColor }: BorderCardProps) => {
  const bars = Array.from({ length: 6 }).map((_, index) => (
    <div
      key={index}
      className={`${type}-short-bar-${index + 1} position-absolute`}
      style={{ backgroundColor: borderColor }}
    />
  ));

  const positionClass = `border-card-${position} position-absolute`;

  return (
    <div className={positionClass} style={{ backgroundColor: borderColor }}>
      {bars}
    </div>
  );
};

interface InfoCardProps {
  heightImg?: string;
  title: string;
  alt?: string;
  text?: string;
  imageSrc?: string;
  borderColor?: string;
  bgColor?: string;
  textColor?: string;
  fourColumn?: boolean;
  customClass?: string;
}

const InfoCard = ({
  heightImg = "70px",
  title,
  text,
  imageSrc,
  borderColor = "black",
  bgColor,
  textColor,
  alt,
  fourColumn = true,
  customClass,
}: InfoCardProps) => {
  const altText = alt || `Icon ${title.toLowerCase()}`;
  const cardClasses = `text-center p-3 position-relative ${
    fourColumn ? "h-100" : customClass ? customClass : ""
  }`;

  const cardContent = (
    <div
      className={cardClasses}
      style={{
        border: `1px solid ${borderColor}`,
        backgroundColor: bgColor,
        color: textColor,
      }}
    >
      {imageSrc && <img src={imageSrc} alt={altText} height={heightImg} />}
      <div className="l-bold">{title}</div>
      {text && <div className="s-light">{text}</div>}
      <BorderCard type="horizontal" position="top" borderColor={borderColor} />
      <BorderCard
        type="horizontal"
        position="bottom"
        borderColor={borderColor}
      />
      <BorderCard type="vertical" position="left" borderColor={borderColor} />
      <BorderCard type="vertical" position="right" borderColor={borderColor} />
    </div>
  );

  return fourColumn ? (
    <Col xs={6} lg={3} className="mb-2 mb-md-4 px-1 px-md-3">
      {cardContent}
    </Col>
  ) : (
    cardContent
  );
};

export default InfoCard;
