import React from "react";
import DstNavbar from "../../../components/DstNavbar/DstNavbar";
import PreFooterOmnes from "../../../components/PreFooterOmnes/PreFooterOmnes";
import DstFooter from "../../../components/DstFooter/DstFooter";
import { Container } from "react-bootstrap";
import { ContactSection } from "../Formation.component";

const DessinateurProjeteurTuyauterie = () => {
  return (
    <React.Fragment>
      <DstNavbar />
      <Container>
        <h1 className="text-center my-4">WIP</h1>
        <p className="h4">
          Nous travaillons actuellement sur la mise en ligne de notre page web.
          En attendant, vous pouvez recevoir notre programme en remplissant le
          formulaire ci-dessous. Merci de votre patience et de votre
          compréhension.
        </p>
      </Container>
      <Container className="mt-3 mt-md-5">
        <ContactSection
          portalId="19831339"
          formId="26f15d01-572b-4e8f-adca-998ac0b0026e"
        />
      </Container>
      <PreFooterOmnes />
      <DstFooter />
    </React.Fragment>
  );
};

export default DessinateurProjeteurTuyauterie;
