import React from "react";
import { Col, Row } from "react-bootstrap";

import DstButton from "../DstButton/DstButton";
import { DstButtonProps } from "../../models/Button";

import "./DstProgram.scss";
interface Item {
  title: string;
  img: string;
}

interface DstProgramProps {
  items: Item[];
  title?: JSX.Element;
  dstButton?: DstButtonProps;
}

const DstProgram = ({ items, title, dstButton }: DstProgramProps) => {
  const pairGroups = items.reduce((acc: Item[][], curr, index) => {
    if (index % 2 === 0) acc.push([]);
    acc[acc.length - 1].push(curr);
    return acc;
  }, []);

  return (
    <React.Fragment>
      <h3 className="mb-md-0">{title}</h3>
      <div className="position-relative mb-3 mb-md-4 program s-semibold">
        <div className="program-number-bar z-1"></div>
        {pairGroups.map((group, groupIndex) => (
          <Row key={groupIndex} className={groupIndex > 0 ? "mt-md-n5" : ""}>
            {group.map((item, itemIndex) => {
              const isEvenCol = itemIndex % 2 === 1;
              return (
                <Col
                  key={itemIndex}
                  xs={12}
                  md={6}
                  className={`${isEvenCol ? "mt-3 mt-md-5" : ""} px-0`}
                >
                  <div
                    className={`border-bottom h-fit position-relative mx-2 mx-md-0 ${
                      isEvenCol ? "pt-4em" : "mt-3 mt-md-0 pt-md-5"
                    }`}
                  >
                    <div
                      className={`d-flex align-items-center justify-content-between ${
                        isEvenCol
                          ? "justify-content-md-end ps-30px"
                          : "pe-30px flex-row-reverse flex-md-row"
                      }`}
                    >
                      {!isEvenCol && (
                        <div className="custom-number d-md-none ms-1">
                          {groupIndex * 2 + itemIndex + 1}
                        </div>
                      )}
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center flex-row-reverse flex-md-row">
                          {isEvenCol && (
                            <div className="text-md-end">{item.title}</div>
                          )}
                          <img src={item.img} alt="icon" height="50px" />
                        </div>
                        {!isEvenCol && <div>{item.title}</div>}
                      </div>
                      {isEvenCol && (
                        <div className="custom-number d-md-none ms-1">
                          {groupIndex * 2 + itemIndex + 1}
                        </div>
                      )}
                    </div>
                    <div
                      className={`position-absolute d-none d-md-block ${
                        itemIndex % 2 === 0 ? "right-position" : "left-position"
                      } custom-number`}
                    >
                      {groupIndex * 2 + itemIndex + 1}
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        ))}
      </div>
      {dstButton && (
        <div className="text-center mb-3 mb-md-5">
          <DstButton {...dstButton} />
        </div>
      )}
    </React.Fragment>
  );
};

export default DstProgram;
