import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { DstButtonProps } from "../../models/Button";
import DstButton from "../DstButton/DstButton";

import "./HeaderContent.scss";

interface HeaderContentProps {
  dstButton?: DstButtonProps;
  buttonCenter?: boolean;
  title?: string | JSX.Element;
  image?: string;
  alt?: string;
  content: JSX.Element;
  contentCenter?: boolean;
  reversed?: boolean;
}

const HeaderContent = ({
  dstButton,
  buttonCenter = false,
  title,
  content,
  image = "/assets/images/header-img/contact.png",
  alt = "contact",
  contentCenter = false,
  reversed = false,
}: HeaderContentProps) => {
  return (
    <div className="position-relative content-header">
      {reversed ? (
        <>
          <div className="head-horizontal-bar-reversed position-absolute dark-background z-1" />
          <div className="head-horizontal-short-bar-1-reversed position-absolute dark-background z-1" />
          <div className="head-horizontal-short-bar-2-reversed position-absolute dark-background z-1" />
          <div className="head-horizontal-short-bar-3-reversed position-absolute dark-background z-1" />
          <div className="head-horizontal-short-bar-4-reversed position-absolute dark-background z-1" />
        </>
      ) : (
        <>
          <div className="head-horizontal-bar position-absolute dark-background z-1" />
          <div className="head-horizontal-short-bar-1 position-absolute dark-background z-1" />
          <div className="head-horizontal-short-bar-2 position-absolute dark-background z-1" />
          <div className="head-horizontal-short-bar-3 position-absolute dark-background z-1" />
          <div className="head-horizontal-short-bar-4 position-absolute dark-background z-1" />
        </>
      )}
      <Container className="my-3 my-md-5">
        <Row
          className={`${
            reversed ? "d-flex flex-column-reverse flex-md-row" : "mt-5"
          } mt-md-3`}
        >
          {reversed ? (
            <>
              <Col md className="position-relative ps-md-0">
                <div className="head-vertical-bar-reversed position-absolute dark-background d-none d-md-block" />
                <div className="head-vertical-short-bar-1-reversed position-absolute dark-background d-none d-md-block" />
                <div className="head-vertical-short-bar-2-reversed position-absolute dark-background d-none d-md-block" />
                <img src={image} alt={alt} className="img-fluid z-0" />
              </Col>
              <Col
                md
                className={`position-relative pe-md-0 ${
                  contentCenter ? "d-flex flex-column align-self-center" : ""
                }`}
              >
                {title && <div className="mt-3">{title}</div>}
                {content && <div>{content}</div>}
                {dstButton && (
                  <div
                    className={`text-center ${
                      buttonCenter ? "" : "text-md-start"
                    }`}
                  >
                    <DstButton {...dstButton} />
                  </div>
                )}
              </Col>
            </>
          ) : (
            <>
              <Col md className="position-relative pe-5 pe-md-0">
                {title && <div className="mt-3">{title}</div>}
                {content && <div>{content}</div>}
                {dstButton && (
                  <div
                    className={`text-center ${
                      buttonCenter ? "" : "text-md-start"
                    }`}
                  >
                    <DstButton {...dstButton} />
                  </div>
                )}
                <div className="head-vertical-bar position-absolute dark-background d-md-none" />
                <div className="head-vertical-short-bar-1 position-absolute dark-background d-md-none" />
                <div className="head-vertical-short-bar-2 position-absolute dark-background d-md-none" />
              </Col>
              <Col md className="position-relative pe-md-0 text-md-end">
                <div className="head-vertical-bar position-absolute dark-background d-none d-md-block" />
                <div className="head-vertical-short-bar-1 position-absolute dark-background d-none d-md-block" />
                <div className="head-vertical-short-bar-2 position-absolute dark-background d-none d-md-block" />
                <img src={image} alt={alt} className="img-fluid z-0" />
              </Col>
            </>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default HeaderContent;
