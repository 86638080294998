import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";

import DstButton from "../../../components/DstButton/DstButton";
import DstFooter from "../../../components/DstFooter/DstFooter";
import DstNavbar from "../../../components/DstNavbar/DstNavbar";
import DstProgram from "../../../components/DstProgram/DstProgram";
import HeaderContent from "../../../components/HeaderContent/HeaderContent";
import HubspotForm from "../../../components/HubspotForm/HubspotForm";
import InfoCard from "../../../components/InfoCard/InfoCard";
import Popup from "../../../components/Popup/Popup";
import PreFooterOmnes from "../../../components/PreFooterOmnes/PreFooterOmnes";
import Title from "../../../components/DstTitle/DstTitle";

import { ContactSection } from "../Formation.component";

import "../Formation.scss";

const Lighting = () => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleShow = () => {
    setShowModal(true);
  };

  const LightingProgram = [
    {
      title: "Architecture des feux",
      img: "/assets/images/icon/rule.svg",
    },
    {
      title: "Bases de l’optique",
      img: "/assets/images/icon/tuyaux.svg",
    },
    {
      title: "La thermique",
      img: "/assets/images/icon/work.svg",
    },
    {
      title: "Injection plastique/rhéologie",
      img: "/assets/images/icon/rule.svg",
    },
    {
      title:
        "Géometrie/Cotation/Iso statisme/ Dispersion/Réglage (MAP)/ Chaine de cote/Qualité Perçue",
      img: "/assets/images/icon/fiche.svg",
    },
    {
      title: "Consommation (rendement énergétique)",
      img: "/assets/images/icon/work-women.svg",
    },
    {
      title: "Réglementation Auto ECE48",
      img: "/assets/images/icon/redaction.svg",
    },
    {
      title: "Réglementation Ferro",
      img: "/assets/images/icon/rule.svg",
    },
    {
      title: "Réglementation Aéro",
      img: "/assets/images/icon/fiche.svg",
    },
    {
      title: "Choc piéton dans l’environnement AUTO",
      img: "/assets/images/icon/balance.svg",
    },
    {
      title: "Systèmes optiques pour l’éclairage extérieur et performance",
      img: "/assets/images/icon/fiche.svg",
    },
    {
      title: "Eclairage intérieur et performance Auto / Ferro / Aéro",
      img: "/assets/images/icon/network-card.svg",
    },
    {
      title: "Electrique / Electronique",
      img: "/assets/images/icon/component-card.svg",
    },
    {
      title: "Découverte du monde de l’automobile (Produit/ process)",
      img: "/assets/images/icon/setting.svg",
    },
    {
      title: "Architecture Véhicule Thermique / Electrique / Hybride",
      img: "/assets/images/icon/setting.svg",
    },
    {
      title: "Animation de réunion",
      img: "/assets/images/icon/rule.svg",
    },
    {
      title: "Prise de parole",
      img: "/assets/images/icon/fiche.svg",
    },
    {
      title: "Rédaction de synthèse",
      img: "/assets/images/icon/rule.svg",
    },
    {
      title: "Management de projet",
      img: "/assets/images/icon/setting-square.svg",
    },
    {
      title: "Planning Constructeurs & Equipementiers (V3P +PIV)",
      img: "/assets/images/icon/light.svg",
    },
    {
      title: "Logiciels de planification et de gestion JIRA",
      img: "/assets/images/icon/light.svg",
    },
    {
      title: "Logiciels de planification et de gestion MS PROJECT",
      img: "/assets/images/icon/light.svg",
    },
    {
      title: "Design",
      img: "/assets/images/icon/light.svg",
    },
    {
      title: "Excel + VBA",
      img: "/assets/images/icon/light.svg",
    },
    {
      title: "Power Point (animation)",
      img: "/assets/images/icon/light.svg",
    },
    {
      title:
        "Canalyzer- Compréhension du signal CAN (Solari sur la durée) Protocole CAN/LINdonnées d’entrée",
      img: "/assets/images/icon/light.svg",
    },
    {
      title: "Diagalyzer (Diagnostique - durée mini)",
      img: "/assets/images/icon/light.svg",
    },
    {
      title: "DDT2000 (Outil RSA)",
      img: "/assets/images/icon/light.svg",
    },
    {
      title: "Construction d’un plan de Validation",
      img: "/assets/images/icon/light.svg",
    },
    {
      title: "Construction d’un plan de Homologation",
      img: "/assets/images/icon/light.svg",
    },
    {
      title: "CATIA V5",
      img: "/assets/images/icon/light.svg",
    },
    {
      title: "3D experience",
      img: "/assets/images/icon/light.svg",
    },
    {
      title: "Anglais technique - Vocabulaire",
      img: "/assets/images/icon/light.svg",
    },
    {
      title: "Immersion projet",
      img: "/assets/images/icon/light.svg",
    },
  ];

  return (
    <React.Fragment>
      <DstNavbar />
      <HeaderContent
        alt="automobile"
        image="/assets/images/home/automobile.png"
        title={
          <h1>
            Formez vos futurs collaborateurs au secteur de l’automobile en tant
            que <span className="blue-font">Parcours Lighting</span>
          </h1>
        }
        content={
          <div className="mt-3">
            <p>
              Grâce au partenariat entre DataScientest et SEGULA Technologies,
              rejoignez un groupe d'ingénierie mondiale à forte croissance en
              tant que <span className="blue-font">System Engineer</span>, après
              une formation accélérée de 11 semaines.
            </p>
            <p>
              Impression 3D, réalité augmentée, véhicule autonome, usine du
              futur... rythment le quotidien de nos 13 000 ingénieux
              collaborateurs, pourquoi pas le vôtre ?
            </p>
          </div>
        }
        dstButton={{
          value: "J’envoie mon cv",
          clickFunction: handleShow,
        }}
      />
      <Container className="my-3 my-md-5">
        <Title text="Automobile" center underlineWidth={30} textUppercase />
        <Title
          text="Le cursus"
          tag="h2"
          className="h3"
          color="#2424FF"
          underlineWidth={10}
          textUppercase
        />

        <DstProgram
          title={
            <React.Fragment>
              Découvrez notre formation en{" "}
              <span className="blue-font">Parcours Lighting</span>
            </React.Fragment>
          }
          items={LightingProgram}
          dstButton={{
            value: "Recevoir le document",
            clickFunction: handleShow,
            variant: "secondary",
          }}
        />
      </Container>
      <div className="dark-background white-font py-3 py-md-5">
        <Container className="my-3 my-md-5">
          <Title
            text="Recrutement"
            tag="h2"
            className="h3"
            color="#20FFA4"
            underlineColor="#9D9FAB"
            textUppercase
          />
          <h3 className="h2 mb-3 mb-md-5">
            Comment s’organise le{" "}
            <span className="green-font">recrutement</span>
          </h3>
          <Row>
            <InfoCard
              title="Définition du besoin"
              text="DataScientest identifie les besoins spécifiques des entreprises et détermine les profils à sourcer, ainsi que la formation adaptée à ces profils."
              imageSrc="/assets/images/icon/write.svg"
              borderColor={"#F7F7F7"}
            />
            <InfoCard
              title="Sourcing"
              text="En accord avec les critères de sélection, DataScientest identifie
            et sélectionne les profils les plus proches des besoins
            spécifiques de l’entreprise. Ces profils bénéficieront d’une
            formation ciblée pour combler leurs lacunes."
              imageSrc="/assets/images/icon/light.svg"
              borderColor={"#F7F7F7"}
            />
            <InfoCard
              title="Formation"
              text="Une fois les profils sélectionnés et validés par l’entreprise, ils
            entament une formation intensive d’une durée maximale de 11
            semaines. À l’issue de cette formation, ils seront pleinement
            qualifiés et certifiés, prêts à répondre aux besoins de
            l’entreprise."
              imageSrc="/assets/images/icon/book.svg"
              borderColor={"#F7F7F7"}
            />
            <InfoCard
              title="Validation et embauche"
              text="La validation de la formation, et donc de l’embauche, est soumise
            à une soutenance à laquelle les équipes de l’entreprise sont
            invitées à assister. La décision finale d’embauche repose
            entièrement entre les mains de l’entreprise cliente."
              imageSrc="/assets/images/icon/work.svg"
              borderColor={"#F7F7F7"}
            />
          </Row>
          <div className="text-center">
            <DstButton
              value="télécharger la brochure"
              btnClass="m-bold"
              clickFunction={handleShow}
            />
          </div>
        </Container>
      </div>
      <Container className="mt-3 mt-md-5">
        <ContactSection
          portalId="19831339"
          formId="ae01a9a0-6ca5-42d5-adaa-26f7645e0e54"
        />
      </Container>
      <PreFooterOmnes />
      <DstFooter />
      <Popup
        show={showModal}
        onClose={handleClose}
        modalTitle="Syllabus Lighting"
      >
        <div>
          <HubspotForm
            portalId="19831339"
            formId="ae01a9a0-6ca5-42d5-adaa-26f7645e0e54"
          />
        </div>
      </Popup>
    </React.Fragment>
  );
};

export default Lighting;
