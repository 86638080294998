import React from "react";

const DropdownMenuItem = ({
  title,
  clickFunction,
  isActive,
}: {
  title?: string;
  clickFunction?: Function;
  isActive?: boolean;
}) => {
  return (
    <div
      className={isActive ? "tab-menu active" : "tab-menu mb-0"}
      onClick={() => (clickFunction ? clickFunction() : null)}
      role="button"
    >
      {title}
    </div>
  );
};

export default DropdownMenuItem;
