import React from "react";
import { Link } from "react-router-dom";
import { Placeholder, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";

import { DstButtonProps } from "../../models/Button";

import "./DstButton.scss";

const DstButton = ({
  backgroundColor,
  btnClass = "m-black",
  btnImageBefore,
  btnImageAfter,
  btnSize,
  btnWidth = "100%",
  clickFunction,
  color,
  disabled,
  link,
  loading = false,
  loadingColor,
  mock = false,
  target = "_self",
  textUppercase = true,
  type = "button",
  value = "Click here",
  variant = "primary",
  disableAnimation = false,
}: DstButtonProps) => {
  const commonStyle = {
    backgroundColor: backgroundColor,
    color: color,
    width: btnSize,
  };

  const getCommonClass = (btnClass: string) => {
    const BOLD_CLASSES = [
      "l-bold",
      "m-regular",
      "m-bold",
      "l-black",
      "s-semibold",
    ];

    const MARGIN_CLASSES = ["my-0", "mt-0", "mb-0", "my-4"];

    const hasBoldClass = BOLD_CLASSES.some((cls) => btnClass.includes(cls));
    const hasMarginClass = MARGIN_CLASSES.some((cls) => btnClass.includes(cls));

    return `${hasMarginClass ? "" : "my-3"} 
    ${hasBoldClass ? "" : "m-black"}
    ${textUppercase ? "text-uppercase" : ""} 
    ${btnClass} 
    buttongeo 
    ${disableAnimation ? "disable-animation" : ""}`;
  };

  const commonClass = getCommonClass(btnClass);

  if (mock) {
    return (
      <Placeholder animation="glow">
        <Placeholder.Button
          className={commonClass}
          style={{
            width: btnWidth,
          }}
          aria-hidden="true"
        />
      </Placeholder>
    );
  }

  return link ? (
    link.startsWith("http") ? (
      <a
        className={commonClass}
        style={commonStyle}
        href={link}
        target={target}
      >
        {value}
      </a>
    ) : (
      <Link
        className={commonClass}
        style={commonStyle}
        to={link}
        target={target}
      >
        {value}
      </Link>
    )
  ) : (
    <Button
      variant={variant}
      className={commonClass}
      size={btnSize}
      style={commonStyle}
      type={type}
      onClick={() => (clickFunction ? clickFunction() : null)}
      disabled={disabled}
    >
      {loading ? (
        <Spinner
          className="d-block"
          color={loadingColor || "white"}
          size={btnSize === "lg" ? undefined : "sm"}
        />
      ) : (
        <React.Fragment>
          {variant === "primary" && !disableAnimation && (
            <React.Fragment>
              <span className="line-1 user-select-none"></span>
              <span className="line-2 user-select-none"></span>
            </React.Fragment>
          )}
          {variant === "secondary" && !disableAnimation && (
            <React.Fragment>
              <span className="line-1 user-select-none"></span>
              <span className="line-2 user-select-none"></span>
              <span className="line-3 user-select-none"></span>
              <span className="line-4 user-select-none"></span>
            </React.Fragment>
          )}
          {btnImageBefore}
          {value}
          {btnImageAfter}
        </React.Fragment>
      )}
    </Button>
  );
};

export default DstButton;
