import React from "react";
import { Link } from "react-router-dom";
import "./DstFooter.scss";
import { Col, Container, Row } from "react-bootstrap";

const LinkList = ({
  links,
  className,
}: {
  links: { text: string; url: string }[];
  className?: string;
}) => {
  return (
    <ul className={`list-unstyled ${className}`}>
      {links.map((link, index) => (
        <li key={index} className="mb-1">
          {link.url.startsWith("http") ? (
            <a
              href={link.url}
              className="text-decoration-none gray-font"
              target="_blank"
              rel="noopener noreferrer"
            >
              {link.text}
            </a>
          ) : (
            <Link to={link.url} className="text-decoration-none gray-font">
              {link.text}
            </Link>
          )}
        </li>
      ))}
    </ul>
  );
};

const DstFooter = () => {
  return (
    <div
      className="dark-background p-3 border-footer py-3 py-md-4 py-lg-5"
      id="footer"
    >
      <Container>
        <Row>
          <Col md={8} lg={7}>
            <Link to="/">
              <img
                src="/assets/images/dst-ingenierie.svg"
                alt="DST Ingénierie"
              />
            </Link>
            <Row className="mt-4 mt-md-5">
              <Col sm={6} md>
                <LinkList
                  className="m-regular"
                  links={[
                    { text: "Notre école", url: "/notre-ecole" },

                    {
                      text: "Contact",
                      url: "/rdv",
                    },
                  ]}
                />
              </Col>
              <Col sm={6} md>
                <LinkList
                  className="m-regular"
                  links={[
                    {
                      text: "Financement",
                      url: "/financement",
                    },
                    {
                      text: "Accueil handicap",
                      url: "/accueil-handicap",
                    },
                    {
                      text: "Mentions légales",
                      url: "/mentions-legales",
                    },
                  ]}
                />
              </Col>
              <Col sm={6} md>
                {/* <LinkList
                  className="m-regular"
                  links={[
                    {
                      text: "Automobile",
                      url: "#",
                    },
                    {
                      text: "Nucléaire",
                      url: "#",
                    },
                    {
                      text: "Ferroviaire",
                      url: "#",
                    },
                    {
                      text: "Aeronautique",
                      url: "#",
                    },
                    {
                      text: "Projet",
                      url: "#",
                    },
                  ]}
                /> */}
              </Col>
            </Row>
          </Col>
          <Col
            md={4}
            lg={5}
            className=" d-flex flex-column align-items-end d-none d-md-flex"
          >
            {/* <DstButton
              btnClass="py-2 px-4 border-0 mt-0 mb-3 btn btn-primary"
              value="Espace apprenant"
              disableAnimation={true}
              link="https://learn.datascientest.com/"
              target="_blank"
            /> */}
            {/* <img
              className="mb-5 mt-3"
              src="../assets/images/footer/moncompteformation.png"
              alt="logo_mon compte formation"
              height={200}
              width={200}
            ></img> */}
          </Col>
        </Row>
        <Row>
          {/* <Col md>
            <div className="d-sm-flex text-center text-sm-start">
              <div className="d-flex justify-content-center flex-column me-3">
                <p className="text-white mb-0 s-semibold">
                  Envie de suivre l'actu de DST Ingénierie ?
                </p>
                <p className="gray-font mb-0 m-regular">
                  Inscrivez-vous à notre newsletter
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-2 mt-sm-0">
                <DstButton
                  btnClass="py-3 my-0"
                  value="S'inscrire"
                  disableAnimation={true}
                  variant="secondary"
                />
              </div>
            </div>
          </Col> */}
          <Col
            md
            className="d-flex justify-content-center justify-content-md-end align-self-end text-md-end"
          >
            <p className="text-white mt-4 mt-md-0 mb-0 s-semibold">
              ©{new Date().getFullYear().toString()} DST Ingénierie —
              Tous droits réservés.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DstFooter;
