import React from "react";
import "./CardHeader.scss";

const CardHeader = ({
  title,
  image,
  backgroundColor = "white",
  bootcamp = false,
  partTime = false,
  alternance = false,
  bootcampText = "Bootcamp",
  partTimeText = "Temps partiel",
  alternanceText = "Alternance",
  link,
}: {
  title?: string;
  image?: string;
  backgroundColor?: string;
  bootcamp?: boolean;
  partTime?: boolean;
  alternance?: boolean;
  bootcampText?: string;
  partTimeText?: string;
  alternanceText?: string;
  link?: string;
}) => {
  return (
    <div
      id="CardH"
      className="formation-item border-top border-end border-start ms-md-1 px-4 py-1"
    >
      <a
        className="d-flex align-items-center text-decoration-none justify-content-between "
        href={`${link}`}
      >
        <div className="d-flex align-items-center">
          <img
            className={`h-img-40 ${backgroundColor}-background`}
            src={image}
            alt={title}
            height={20}
          />
          <h4 className="xl-regular black-font ms-3 mb-0">{title}</h4>
        </div>

        <i className="dst-icon-arrow-narrow-right ms-2  fw-bold" />
      </a>
    </div>
  );
};

export default CardHeader;
